import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import requests from "../utils/Requests";
import { Link } from "react-router-dom";
const Attractionbygroup = ({ attList }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Carousel responsive={responsive}>
        {attList.map((attraction, index) => (
          <div key={index}>
            <Link to={`${attraction.attractionsId}`}>
              <div className="single-upconing-card">
                <div
                  className="shadow"
                  style={{
                    backgroundImage:
                      requests.imgpath + attraction.attThumbnailImage,
                  }}
                >
                  <img
                    src={requests.imgpath + attraction.attThumbnailImage}
                    alt="img"
                    height="380px"
                  />
                </div>
                <div className="tp-price-meta">
                  <h2>
                    {attraction.price} &nbsp;&nbsp; <small>AED</small>
                  </h2>
                  <p>Price</p>
                </div>
                <div className="details">
                  <h3 className="title">{attraction.attName}</h3>
                  <p>
                    <i className="fa fa-map-marker" />
                  </p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default Attractionbygroup;

import React from "react";
import { Button } from "reactstrap";
import { Form, Field, reduxForm } from "redux-form";
import RenderField from "../../form-components/RenderField";
const TestBookingForm = (props) => {
  const { handleSubmit } = props;

  const sumbitToApi = (values) => {
    console.log(values);
  };
  return (
    <>
      <Form>
        <Field name="TestName" component={RenderField.RenderTextField} />
        <Button
          onClick={handleSubmit((values) =>
            sumbitToApi({
              ...values,
              pill: 1,
            })
          )}
        >
          Submit
        </Button>
      </Form>
    </>
  );
};
export default reduxForm({
  form: "TestBookingForm",
})(TestBookingForm);

// 192.168.225.59  serverpostBookingpaymentRegistration: 103.235.106.127    //66.29.149.191   https://parmartours.com:8443/parmartour/  //local http://192.168.1.2:8443/
const baseURL = "https://parmartours.com:8443/parmartour-test/v1.0/"; //  https://www.parmartours.com/
//v1.54254785215/   "https://parmartours.com:8443/parmartour/v1.0/"
const baseURLMof = "https://parmartours.com:8443/parmartour-test/mof/";
const imagebaseUrl = "https://parmartours.com:8443/"; // server for image https://parmartours.com:8443/  Test Server http://103.235.106.127:8080/parmartour/
const requests = {
  // https://66.29.149.191:8443/parmartour/v1.0/getTicketTypeForAttraction  http://192.168.0.101:8443/v1.0/
  apiKey: "uZFEucIHAbqvgT7p87qC4Ms4tjqG34su",
  setStripeBurjKhalifaPaymentsDetail:
    baseURL + "setStripeBurjKhalifaPaymentsDetail",
  setTestStripeBurjKhalifaPaymentsDetail:
    baseURL + "setTestStripeBurjKhalifaPaymentsDetail",
  getAttractionHolidayListDto: baseURL + "getAttractionHolidayListDto",
  getVisaList: baseURL + "getVisaList",
  setComboBooking: baseURL + "setComboBooking",
  getComboOfferList: baseURL + "getComboOfferList",
  getTourCategoryList: baseURL + "getTourCategoryList",
  checkAddonBarcodeAvailability: baseURL + "checkAddonBarcodeAvailability",
  getWebsiteBannerImagesList: baseURL + "getWebsiteBannerImagesList",
  setCartInfo: baseURL + "setCartInfo",
  getAttractionListForUpdate: baseURL + "getAttractionListForUpdate",
  deleteCartInfo: baseURL + "deleteCartInfo",
  getCartInfoList: baseURL + "getCartInfoList",
  setVisaEnquiry: baseURL + "setVisaEnquiry",
  getagencylist: baseURL + "getagencylist",
  getPaymentConfirmationBurjKhalifa:
    baseURL + "getPaymentConfirmationBurjKhalifa",
  confirmBurjTicket: baseURL + "confirmBurjTicket",
  saveBurjTicket: baseURL + "saveBurjTicket",
  getBurjTicketTypeWithRates: baseURL + "getBurjTicketTypeWithRates",
  getBurjTimeSlotWithRates: baseURL + "getBurjTimeSlotWithRates",
  getBurjTimeSlot: baseURL + "getBurjTimeSlot",
  getAgencyPaymentUrl: baseURL + "getAgencyPaymentUrl",
  getSalesReportForB2b: baseURL + "getSalesReportForB2b",
  getPaymentConfirmation: baseURL + "getPaymentConfirmation",
  getPaymentGatewayUrl: baseURL + "getPaymentGatewayUrl",
  paymentRegistration: baseURL + "paymentRegistration",
  getAttractionTicketTypePrice: baseURL + "getAttractionTicketTypePrice",
  getTicketTypeListByAttraction: baseURL + "gettickettypelistbyattraction",
  getTicketGroupListForAttraction: baseURL + "getTicketGroupListForAttraction",
  getattractionallbygroup: baseURL + "getattractionallbygroup",
  getagencydetails: baseURL + "getagencydetails",
  getAttractionAll: baseURL + "getattractionall",
  getImgBaseUrl: imagebaseUrl + "filestorage/parmartour/images/",
  getAttractionDetails: baseURL + "getattractiondetails",
  getTourPackageList: baseURL + "gettourpackageall",
  getTourPackageSingle: baseURL + "gettourpackageDetails",
  getallparkgroup: baseURL + "getattractiongrouplist",
  setaddonformlist: baseURL + "setaddonformlist",
  getaddonbannerlist: baseURL + "getaddonbannerlist",
  getuserlogindetails: baseURL + "getloginuserdetails",
  setotpforexistinguser: baseURL + "setotpforexistinguser",
  updatePasswordForUser: baseURL + "updatePasswordForUser",
  postBooking: baseURL + "setbooking",
  setloginuserdetails: baseURL + "setloginuserdetails",
  verifyotpuser: baseURL + "verifyotpuser",
  getVisaList: baseURL + "getVisaList",
  setenquiry: baseURL + "setenquiry",
  setStripePaymentsDetail: baseURL + "setStripePaymentsDetail",
  setTestStripePaymentsDetail: baseURL + "setTestStripePaymentsDetail",
  imgpath: imagebaseUrl + "filestorage/parmartour/images/",
  setBookingIp: baseURL + "setBookingIp",
  setagencyuser: baseURL + "setagencyuser",
  getagencyuserlistforagency: baseURL + "getagencyuserlistforagency",
  setNetworkPaymentsDetail: baseURL + "setNetworkPaymentsDetail",
  getAttractionListB2C: baseURL + "getAttractionListB2C",

  setuserroleslist: baseURL + "setuserroleslist",

  setagencyuser: baseURL + "setagencyuser",
  gettransactionlist: baseURL + "gettransactionlist",

  getTopupList: baseURL + "getTopupList",

  getAvailability: baseURLMof + "getAvailability",
  addToCart: baseURLMof + "addToCart",
  confirmTicket: baseURLMof + "confirmTicket",
  emptyShopCart: baseURLMof + "emptyShopCart",
  removeShopCart: baseURLMof + "removeShopCart",
  getTicketPrice: baseURLMof + "getTicketPrice",
  editShopCart: baseURLMof + "editShopCart",
};
export default requests;

import React, { useEffect } from "react";
import TopNav from "./top_nav";
import CommonBanner from "./common_banner";
import Subscribe from "./subscribe";
import Footer from "./footer";
import encryptStorage from "../../utils/Encryptstorage";
const BookingFailiure = () => {
  useEffect(() => {
    encryptStorage.removeItem("DFuck");
    encryptStorage.removeItem("url");
    encryptStorage.removeItem("suckapi");
    encryptStorage.removeItem("firstSubmitForBooking");
  }, []);
  return (
    <div>
      <TopNav />
      <CommonBanner imgUrl="thankyou.jpg" />
      <div className="container">
        <h3 className="title">Sorry Payment Was Not Success ,</h3>

        <p>
          We are not recived any payment from you, please try again, Thank You
        </p>
      </div>
      <Subscribe />
      <Footer />
    </div>
  );
};

export default BookingFailiure;

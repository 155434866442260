const CommonBanner = ({ title, imgUrl }) => {
  // let HeaderTitle = this.props.headertitle;
  let publicUrl = process.env.PUBLIC_URL + "/assets/img/banner/";
  //let testurl = 'attBanner.jpeg';
  let image = publicUrl + imgUrl;
  // let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle;

  return (
    <>
      <div
        className="breadcrumb-area jarallax"
        style={{ backgroundImage: "url(" + image + ")" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner">
                <h1 className="page-title">{title}</h1>
                {/* <ul className="page-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Subheader</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonBanner;

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import requests from "../../utils/Requests";

const TourPackageList = () => {
  let login = sessionStorage.getItem("isLoggedIn");
  useEffect(() => {
    getAllTourList();
    getTourGroup();
  }, []);
  const [parkGroup, setparkGroup] = useState([]);
  const [search, setsearch] = useState("");
  const [tourlist, setTourlist] = useState([]);
  const [allTourList, setallTourList] = useState([]);
  const [getTourList, setgetTourList] = useState({
    attractionId: 1,
    currencyCode: "AED",
    platformId: 1,
  });

  const getTourGroup = () => {
    axios
      .post(requests.getTourCategoryList, { userListId: 0 })
      .then((res) => {
        let tempVal = [...parkGroup];
        tempVal.length = 0;
        tempVal.push({ value: "all-tours", name: "All Tour Packs" });
        for (let i = 0; i < res.data.length; i++) {
          //tourCategoryId, categoryName
          tempVal.push({
            name: res.data[i].categoryName,
            value: res.data[i].tourCategoryId,
          });
        }
        setparkGroup(tempVal);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllTourList = async () => {
    await axios
      .post(requests.getTourPackageList, getTourList)
      .then((res) => {
        // console.log(res.data);
        setTourlist(res.data);
        setallTourList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterItems = (FilterList) => {
    if (FilterList === "all-tours") {
      const toursfilter = allTourList.filter(
        (tour) => tour.tourGroup !== FilterList
      );

      setTourlist(toursfilter);
    } else {
      const toursfilter = allTourList.filter(
        (tour) => tour.tourGroup === FilterList
      );

      setTourlist(toursfilter);
    }
    window.scrollTo(0, 0);
    //const newItem = tourlist.filter((tour) => tour.attGroup === FilterList);
    //setTourlist( toursfilter.filter((tour) => tour.attGroup === FilterList));
  };

  const fulterAttraction = tourlist.filter((attract) => {
    return attract.tourName
      ? attract.tourName.toLowerCase().includes(search.toLocaleLowerCase())
      : "".toLowerCase().includes(search.toLocaleLowerCase());
  });

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  return (
    <>
      <div className="tour-list-area pd-top-120 viaje-go-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-8 order-lg-12">
              {fulterAttraction.map((tour, index) => (
                <div key={index}>
                  <div className="tour-list-area">
                    <div className="single-destinations-list style-three">
                      <div className="thumb">
                        <img
                          src={requests.imgpath + tour.tourLogo}
                          alt="list"
                        />
                      </div>
                      <div className="details">
                        <p className="location">
                          <img
                            src={publicUrl + "assets/img/icons/1.png"}
                            alt="map"
                          />
                          {tour.tourCity}
                        </p>
                        <h4 className="title">
                          <Link
                            to={`tour-day-pack-details/${tour.tourPackageId}`}
                          >
                            {tour.tourName}
                          </Link>
                        </h4>

                        {tour.tourDescription && (
                          <>
                            <p className="content">
                              {" "}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: tour.tourDescription.substring(
                                    0,
                                    250
                                  ),
                                }}
                              />
                            </p>
                          </>
                        )}

                        <div className="d-flex justify-content-end">
                          {login === "yes" && (
                            <>
                              <div className="tp-price-meta d-inline-block">
                                <p>Number of days</p>
                                <i className="fa fa-clock-o" />{" "}
                                <h2>{tour.tourNofDays} </h2>
                              </div>
                              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                              <div className="tp-price-meta d-inline-block">
                                <p>Price</p>
                                <div style={{ display: "flex" }}>
                                  <h2>
                                    {tour.tourPrice}&nbsp; &nbsp;{" "}
                                    <span>AED</span>
                                  </h2>
                                </div>
                              </div>
                            </>
                          )}

                          <div
                            className="tp-price-meta d-inline-block pull-right"
                            style={{ marginTop: "20px" }}
                          >
                            <Link
                              class="btn btn-yellow pull-right"
                              to={`tour-day-pack-details/${tour.tourPackageId}`}
                            >
                              Send Enquiry
                              {/* <i class="fa fa-paper-plane"></i> */}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="col-xl-3 col-lg-4 order-lg-1">
              <div className="sidebar-area">
                <div className="widget-tour-list-search">
                  <div className="search-form">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </div>
                    <button className="submit-btn" type="submit">
                      <i className="ti-search" />
                    </button>
                  </div>
                </div>
                <ul class="nav nav-tabs tp-tabs style-two">
                  {parkGroup &&
                    parkGroup.map((parkGroup, index) => (
                      <>
                        <li class="nav-item" key={index}>
                          <a
                            class="nav-link "
                            data-toggle="tab"
                            href=""
                            value={parkGroup.value}
                            onClick={() => filterItems(parkGroup.value)}
                          >
                            {parkGroup.name}
                          </a>
                        </li>
                      </>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TourPackageList;

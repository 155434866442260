/** @format */

import { Input, FormGroup, Dropdown } from "reactstrap";
import Switch from "react-switch";
import Select from "react-select";
import DatePicker from "react-datepicker";
import React, { useEffect } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
const RenderTextField = ({
  input,
  label,
  type,
  icon,
  predefindvalue,
  disabled,
  placeholder,
  onClick,
  meta: { asyncValidating, touched, error },
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(); // Call the provided onClick function if it exists
    }
  };
  return (
    <>
      <div className="single-widget-search-input-title">
        <i className={icon} /> {label}
      </div>
      <div className={asyncValidating ? "async-validating" : ""}>
        <Input
          {...input}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          onClick={handleClick}
        />
      </div>
      <div className="err-msg">{touched && error && <span>{error}</span>}</div>
    </>
  );
};

const renderOptionField = ({
  input,
  label,
  type,
  customfeild,
  icon,

  meta: { asyncValidating, touched, error },
}) => (
  <>
    <div className="single-widget-search-input-title">
      <i className={icon} /> {label}
    </div>
    <Input {...input} type={type} placeholder={label}>
      {customfeild.map((optvalue, key) => (
        <option key={key} name={optvalue.value} value={optvalue.value}>
          {optvalue.name}
        </option>
      ))}
    </Input>
    <div className="err-msg">{touched && error && <span>{error}</span>}</div>
  </>
);

const renderRadioButton = ({
  input,
  label,
  type,
  icon,
  customfeild,
  meta: { asyncValidating, touched, error },
}) => (
  <div>
    <div className={asyncValidating ? "async-validating" : ""}>
      {/* <Label for={label}>{label}</Label> */}
      {}
      <Input {...input} type={type} name={label} value={customfeild} />
      {label}
    </div>
    <div className="err-msg">{touched && error && <span>{error}</span>}</div>
  </div>
);

const renderDisabledField = ({
  input,
  label,
  type,
  icon,
  customfeild,
  meta: { asyncValidating, touched, error },
}) => (
  <div>
    <div className={asyncValidating ? "async-validating" : ""}>
      {/* <Label for={label}>{label}</Label> */}
      {}
      <Input {...input} type={type} name={label} value={customfeild} disabled />
      {label}
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

const RendercheckboxField = ({
  input,
  label,
  type,
  customfeild,
  meta: { asyncValidating, touched, error },
}) => (
  <div>
    <input type="checkbox" {...input} /> &nbsp;&nbsp;&nbsp;&nbsp;
    {label}
    <div className="err-msg">{touched && error && <span>{error}</span>}</div>
  </div>
);

var dates = ["20/01/2012", "21/01/2012", "22/01/2012", "23/01/2012"];

const RenderDateField = ({
  input,
  label,
  type,
  icon,
  meta: { asyncValidating, touched, error },
}) => {
  return (
    <>
      <div className="single-widget-search-input-title">
        <i className={icon} /> {label}
      </div>
      <div className={asyncValidating ? "async-validating" : ""}>
        <div className="input-group date" id="datetimepickerDemo">
          <input {...input} type={type} className="form-control" />
          <span className="input-group-addon">
            <span className="glyphicon glyphicon-calendar" />
          </span>
        </div>
      </div>
      <div className="err-msg">{touched && error && <span>{error}</span>}</div>
    </>
  );
};

const RenderSelectField = ({
  input,
  label,
  type,
  options,
  icon,
  meta: { asyncValidating, touched, error },
}) => (
  <>
    <div className="single-widget-search-input-title">
      <i className={icon} /> {label}
    </div>
    <div className={asyncValidating ? "async-validating" : ""}>
      <Select
        {...input}
        // onChange={(event, value) => input.onChange(value)}
        // onChange={value => input.onChange(value)}
        value={options.find((c) => c.value === input.value)}
        onChange={(val) => input.onChange(val.value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
      />
    </div>
    <div className="err-msg">{touched && error && <span>{error}</span>}</div>
  </>
);

const renderDatePicker = ({
  input,
  placeholder,
  minDate,
  maxDate,
  label,
  icon,
  holydays,
  enabledDates,
  meta: { touched, error },
}) => {
  const CustomeInputField = ({ date, value, onChange, onClick }) => (
    <div className="input-group">
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="form-control"
        onClick={onClick}
      />
      <span className="input-group-addon">
        <span className="glyphicon glyphicon-calendar" />
      </span>
    </div>
  );

  console.log("holy days", holydays);
  return (
    <div>
      <div className="single-widget-search-input-title">
        <i className={icon} /> {label}
      </div>
      <DatePicker
        className="plus-icon"
        dateFormat="yyyy/MM/dd"
        selected={input.value || null}
        onChange={input.onChange}
        minDate={minDate}
        maxDate={maxDate}
        excludeDates={holydays}
        disabledKeyboardNavigation
        placeholderText={placeholder}
        includeDates={enabledDates} // Specify the enabled dates here
        customInput={<CustomeInputField />}
      />
      <div className="err-msg">{touched && error && <span>{error}</span>}</div>
    </div>
  );
};
export default {
  RenderTextField,
  renderOptionField,
  renderRadioButton,
  renderDisabledField,
  RendercheckboxField,
  RenderDateField,
  RenderSelectField,
  renderDatePicker,
};

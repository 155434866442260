import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import axios from "axios";
import requests from "../../utils/Requests";
import { Button, ModalHeader, Modal, ModalBody } from "reactstrap";
import UpdateAgencyUser from "./UpdateAgencyUser";
const SubUserList = ({ agentId }) => {
  useEffect(() => {
    getUserList();

    console.log("idForReport", agentId);
  }, [agentId]);
  const [modal, setModal] = useState(false);
  const [subuserData, setsubuserData] = useState();

  const toggleModel = () => setModal(!modal);
  const [rowData, setrowData] = useState([]);
  const getUserList = () => {
    console.log("ajbxiabxiabxibaivxiavcivi 8o37rt8 23trc82t3r8 23t");
    axios
      .post(requests.getagencyuserlistforagency, {
        agencyId: agentId,
        secretKey: requests.apiKey,
        withPassword: true,
      })
      .then((res) => {
        console.log(res.data);
        setrowData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const columnDefs = [
    { headerName: "F_Name", field: "userFirstName" },
    { headerName: "L_Name", field: "userLastName" },
    { headerName: "Email ", field: "userEmail" },
    {
      headerName: "Action",
      filter: false,
      field: "agencyUserId",
      cellRendererFramework: (params) => (
        <div>
          <Button color="primary" onClick={() => actionButton(params)}>
            Edit
          </Button>
        </div>
      ),
    },
  ];

  const [agentIdedit, setagentIdedit] = useState();
  const actionButton = (params) => {
    // console.log(params);
    // alert(`${params.data.agencyUserId} `);

    const fintAgent = rowData.find(
      (item) => item.agencyUserId === params.data.agencyUserId
    );
    setsubuserData(fintAgent);
    console.log(`${JSON.stringify(fintAgent, null, 2)}`);
    setagentIdedit(params.data.agencyUserId);
    toggleModel();
    // history.push(
    //   `/admin/agency-create-new/${params.data.agencyUserId}`
    // ); //{`tour-pack-details/${alltour.attractionsId}`}"/attraction/attraction-creation"
  };

  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggleModel}
        className="modal-dialog modal-lg"
      >
        <ModalHeader toggle={toggleModel}>Create New User</ModalHeader>
        <ModalBody>
          <UpdateAgencyUser userData={subuserData} />
        </ModalBody>
      </Modal>
      <div className="ag-theme-alpine" style={{ height: "500px" }}>
        <AgGridReact
          id="AgencyUser"
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          // onGridReady={onGridReady}
          pagination={true}
          // paginationPageSize={5}
          // paginationAutoPageSize={true}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default SubUserList;

/** @format */

import axios from "axios";
import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RouteMenu from "../../routes/route-menu";
import encryptStorage from "../../utils/Encryptstorage";
import requests from "../../utils/Requests";
import AgentAcc from "./agent_acc";
const TopNav = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "logo";
  let anchor = "#";
  const [LoggedIn, setLoggedIn] = useState(0);
  const [CusName, setCusName] = useState("Guest");

  const [cartCount, setcartCount] = useState();

  const [agentData, setagentData] = useState("");
  // const [cartGetObj, setcartGetObj] = useState({
  //   userType: "",
  //   b2bId: "",
  //   b2bUserId: "",
  //   b2cId: "",
  //   secretKey: requests.apiKey,
  // });
  useEffect(() => {
    let isLoggedIn = sessionStorage.getItem("isLoggedIn");

    let userType;
    if (isLoggedIn == "yes") {
      const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId

      let urlObject = JSON.parse(enl);

      if (urlObject.b2b == true) {
        setagentData(urlObject);

        setCusName(urlObject.agency.agencyName);
        userType = 1;
        // setcartGetObj({
        //   ...cartGetObj,
        //   userType: 1,
        //   b2bId: urlObject.agency.agencyId,
        // });
        getCartDetails(userType, urlObject.agency.agencyId);
      } else if (urlObject.b2bUser == true) {
        setCusName(
          urlObject.agencyUser.userFirstName + urlObject.agencyUser.userLastName
        );
        userType = 2;
        // setcartGetObj({
        //   ...cartGetObj,
        //   userType: 2,
        //   b2bId: urlObject.agencyUser.agencyUserId,
        // });
        getCartDetails(userType, urlObject.agencyUser.agencyUserId);
      } else if (urlObject.b2c == true) {
        userType = 3;
        getCartDetails(userType, urlObject.customer.customerId);
      } else {
      }

      setLoggedIn(1);
    }
  }, [setLoggedIn, setCusName, props.refresh]);

  const getCartDetails = (uType, uId) => {
    let b2b = "",
      b2bu = "",
      b2c = "";
    if (uType == 1) {
      b2b = uId;
    } else if (uType == 2) {
      b2bu = uId;
    } else {
      b2c = uId;
    }
    const submitObject = {
      userType: uType,
      b2bId: b2b,
      b2bUserId: b2bu,
      b2cId: b2c,
      secretKey: requests.apiKey,
      platformId: 1,
    };

    axios
      .post(requests.getCartInfoList, submitObject)
      .then((response) => {
        setcartCount(response.data.length);
      })
      .catch(() => {});
  };
  const logout = (e) => {
    sessionStorage.clear();
    window.location.reload();
    // console.log("clear");
    window.location.href = "/";
  };

  return (
    <>
      <nav className="navbar navbar-area navbar-expand-lg nav-style-01 viaje-go-top">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <div className="mobile-logo">
              <Link to="/">
                <img
                  src={publicUrl + "assets/img/logoHeader.png"}
                  height="65px"
                  width="130px"
                  alt={imgattr}
                />
              </Link>{" "}
              &nbsp;&nbsp;
              <div className="nav-right-content">
                <ul>
                  {LoggedIn == 1 ? (
                    <>
                      <li className="search">
                        {/* <i className="ti-search" /> */}
                        <Link to="/user-profile-page">{CusName}</Link>
                      </li>
                      <li className="notification">
                        <a className="signUp-btn" onClick={(e) => logout(e)}>
                          Logout
                          <i className="fa fa-user-o" />
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      {" "}
                      <li className="notification">
                        <Link to="/login-page">
                          Login
                          <i className="fa fa-user-o" />
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>

            <button
              className="navbar-toggler float-right"
              type="button"
              data-toggle="collapse"
              data-target="#tp_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggle-icon">
                <span className="line" />
                <span className="line" />
                <span className="line" />
              </span>
            </button>
            <div className="nav-right-content">
              <ul></ul>
            </div>
          </div>

          {/* navigation menu */}

          <div className="collapse navbar-collapse" id="tp_main_menu">
            <div className="logo-wrapper desktop-logo">
              <Link to="/" className="main-logo">
                <img
                  src={publicUrl + "assets/img/logoHeader.png"}
                  height="65px"
                  width="180px"
                  alt="logo"
                />
              </Link>
              <Link to="/" className="sticky-logo">
                <img
                  src={publicUrl + "assets/img/logoHeader.png"}
                  height="65px"
                  width="180px"
                  alt="logo"
                />
              </Link>
            </div>
            <ul className="navbar-nav">
              {RouteMenu.map((menu, index) =>
                menu.fixMenu ? (
                  <React.Fragment key={index}>
                    <li>
                      <Link to={menu.path}>{menu.name}</Link>
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index}>
                    <li className="menu-item-has-children">
                      <Link to="#">{menu.name}</Link>
                      <ul className="sub-menu">
                        {menu.SubMemu.map((smenu, sindex) => (
                          <li key={sindex}>
                            <Link to={smenu.path}>{smenu.name}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </React.Fragment>
                )
              )}
            </ul>
          </div>

          <div className="nav-right-content">
            <ul>
              {LoggedIn == 1 ? (
                <>
                  <li>
                    <Link to="/user-cart-details">
                      <i
                        class="fa fa-shopping-cart fa-2x"
                        aria-hidden="true"
                      ></i>
                      <span className="badge badge-warning" id="lblCartCount">
                        {cartCount}
                      </span>
                    </Link>
                  </li>
                  <li>{/* <p>{props.cartQty}</p> */}</li>
                  <li className="search">
                    {/* <i className="ti-search" /> */}
                    <Link to="/user-profile-page">
                      Hello &nbsp; &nbsp; {CusName}
                    </Link>
                  </li>
                  <li className="notification">
                    <a className="signUp-btn" onClick={(e) => logout(e)}>
                      Logout
                      <i className="fa fa-user-o" />
                    </a>
                  </li>
                </>
              ) : (
                <>
                  {" "}
                  <li className="notification">
                    <Link to="/login-page">
                      Login
                      <i className="fa fa-user-o" />
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>

      {agentData != "" ? (
        <AgentAcc agentData={agentData} />
      ) : (
        <>
          <br></br>
          <br></br>
          <br></br>
        </>
      )}
    </>
  );
};

export default TopNav;

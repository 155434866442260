import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import { reduxForm, Field } from "redux-form";
import RenderField from "../../form-components/RenderField";
import axios from "axios";
import requests from "../../utils/Requests";
import Swal from "sweetalert2";
const validate = (values) => {
  const errors = {};
  if (!values.userFirstName) {
    errors.userFirstName = "Required";
  }
  if (!values.userLastName) {
    errors.userLastName = "Required";
  }
  if (!values.userEmail) {
    errors.userEmail = "Required";
  }

  if (!values.userPassword) {
    errors.userPassword = "Required";
  } else if (values.userPassword.length < 8) {
    errors.userPassword = "Minimum be 8 characters or more";
  } else if (!/[A-Z]/.test(values.userPassword)) {
    errors.userPassword = "Minimum one upper Case";
  } else if (!/[\d`~!@#$%\^&*()+=|;:'",.<>\/?\\\-]/.test(values.userPassword)) {
    errors.userPassword = "Minimum one special character";
  }
  if (!values.password || values.userPassword != values.password) {
    errors.password = "PassWord Not Match";
  }

  return errors;
};
const CreateSubUser = (props) => {
  const { handleSubmit, agentId } = props;

  const agencyStatus = [
    { name: "Active", value: true },
    { name: "In-Active", value: false },
  ];
  const createNow = (values) => {
    const subObj = {
      ...values,
      agencyId: agentId,
      userRoleId: 1,
    };

    axios
      .post(requests.setagencyuser, subObj)
      .then((res) => {
        if (res.status == 200) {
          Swal.fire({
            title: "Success", //'Good job!',
            text: "Agency Added", //'You clicked the button.',
            icon: "success", //'success'
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <Row>
        <Col>
          <Field
            name="userFirstName"
            type="text"
            label="First Name"
            component={RenderField.RenderTextField}
          />
        </Col>
        <Col>
          {" "}
          <Field
            name="userLastName"
            type="text"
            label="Last Name"
            component={RenderField.RenderTextField}
          />
        </Col>
        <Col>
          {" "}
          <Field
            name="activeStatus"
            type="select"
            customfeild={agencyStatus}
            label="Active Status"
            component={RenderField.renderOptionField}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            name="userEmail"
            type="email"
            label="Email"
            component={RenderField.RenderTextField}
          />
        </Col>
        <Col>
          {" "}
          <Field
            name="userPassword"
            type="password"
            label="password"
            component={RenderField.RenderTextField}
          />
        </Col>
        <Col>
          {" "}
          <Field
            name="password"
            type="password"
            label="Confirm Password"
            component={RenderField.RenderTextField}
          />
        </Col>
      </Row>
      <div
        className="float-right"
        style={{
          marginTop: 10,
        }}
      >
        <Button
          color="primary"
          type="submit"
          onClick={handleSubmit((values) => createNow(values))}
        >
          Save
        </Button>{" "}
        &nbsp; &nbsp;&nbsp;
      </div>
    </div>
  );
};

export default reduxForm({
  form: "CreateSubUser",
  validate,
})(CreateSubUser);

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import requests from "../utils/Requests";
import DynamicBanner from "../pages/global-components/dynamic_banner";
import { Row, Col } from "reactstrap";
import TopNav from "../pages/global-components/top_nav";
import Subscribe from "../pages/global-components/subscribe";
import Footer from "../pages/global-components/footer";
const ComboPack = () => {
  let login = sessionStorage.getItem("isLoggedIn");
  useEffect(() => {
    getComboPack();
  }, []);
  const [comboPack, setcomboPack] = useState([]);
  const getComboPack = () => {
    axios
      .post(requests.getComboOfferList, { currencyCode: "AED", platformId: 1 })
      .then((res) => {
        const tempData = res.data;

        console.log("res.data", tempData);

        const filterData = tempData.filter(
          (item) => item.statusActive === true
        );
        console.log("res.data", filterData);
        setcomboPack(filterData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [search, setsearch] = useState("");

  const fulterAttraction = comboPack.filter((comboPack) => {
    return comboPack.comboName
      ? comboPack.comboName.toLowerCase().includes(search.toLocaleLowerCase())
      : "".toLowerCase().includes(search.toLocaleLowerCase());
  });
  return (
    <>
      <TopNav />
      <DynamicBanner title="" />

      <div className="tour-list-area pd-top-120 viaje-go-top">
        <div className="container">
          <div className="widget-tour-list-search">
            <div className="search-form">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setsearch(e.target.value)}
                />
              </div>
              <button className="submit-btn" type="submit">
                <i className="ti-search" />
              </button>
            </div>
          </div>
          {fulterAttraction.map((comboPack, index) => (
            <>
              <div key={index}>
                <div className="tour-list-area">
                  <div className="single-destinations-list style-three">
                    <div className="thumb">
                      <Link to={`combo-pack-details/${comboPack.comboOfferId}`}>
                        <img
                          // src={publicUrl + "assets/img/destination-list/12.png"}
                          src={requests.imgpath + comboPack.thumbImageFile}
                          alt="list"
                        />
                      </Link>
                    </div>

                    <div className="details">
                      <h4 className="title">
                        {/* <Link to="/tour-details"></Link> */}
                        <Link
                          to={`combo-pack-details/${comboPack.comboOfferId}`}
                        >
                          {comboPack.comboName}
                        </Link>
                      </h4>

                      {comboPack.comboDescription ? (
                        <p className="content">
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: comboPack.comboDescription.substring(
                                0,
                                250
                              ),
                            }}
                          />
                        </p>
                      ) : null}

                      <div className="d-flex justify-content-end">
                        {login === "yes" && (
                          <>
                            <div className="tp-price-meta d-inline-block">
                              <p>Actual Price </p>
                              <h5>
                                <del>
                                  {comboPack.actualPrice}
                                  <span>AED</span>
                                </del>
                              </h5>
                            </div>
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            <div className="tp-price-meta d-inline-block">
                              <p>Offer Price</p>
                              <h5>
                                {comboPack.offerB2bAdultPrice}
                                <span>AED</span>
                              </h5>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                          </>
                        )}

                        <div
                          className="tp-price-meta d-inline-block pull-right"
                          style={{ marginTop: "20px" }}
                        >
                          <Link
                            className="btn btn-yellow float-end"
                            to={`combo-pack-details/${comboPack.comboOfferId}`}
                          >
                            Book
                            {/* <i class="fa fa-paper-plane"></i> */}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <Subscribe />
      <Footer />
    </>
  );
};

export default ComboPack;

import encryptStorage from "./Encryptstorage";
export const usData = () => {
  const data = encryptStorage.getItem("enl");
  if (data) {
    const usObject = JSON.parse(data);

    const inputString = usObject.randomKey;

    if (usObject.randomKey == null) {
      return null;
    } else {
      // Reverse the input string
      const reversedString = inputString.split("").reverse().join("");

      // Split the reversed string into groups of 10 characters
      const splitString = reversedString.match(/.{1,10}/g);

      // Rearrange the groups according to the specified pattern
      const rearrangedString = [
        splitString[4],
        splitString[1],
        splitString[6],
        splitString[5],
        splitString[3],
        splitString[0],
        splitString[9],
        splitString[2],
        splitString[7],
        splitString[8],
      ].join("");
      // console.log("afterCOnvert", rearrangedString);
      return rearrangedString;
    }
  }
};

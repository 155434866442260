/** @format */

import { reduxForm, Form, Field } from "redux-form";
import RenderField from "../form-components/RenderField";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import requests from "../utils/Requests";
import Swal from "sweetalert2";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TopNav from "../pages/global-components/top_nav";
import Subscribe from "../pages/global-components/subscribe";
import Footer from "../pages/global-components/footer";
import DynamicBanner from "../pages/global-components/dynamic_banner";
import Collapsible from "react-collapsible";
import AttBookingForm from "./AttBookingForm";
import encryptStorage from "../utils/Encryptstorage";
const ComboDetails = () => {
  let { id } = useParams();
  const [loginData, setloginData] = useState([]);
  const [userId, setuserId] = useState({
    agencyId: 0,
    agencyUserId: 0,
    customerId: 0,
  });
  let login = sessionStorage.getItem("isLoggedIn");
  useEffect(() => {
    getcomboList();

    if (login == "yes") {
      const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      let urlObject = JSON.parse(enl);
      setloginData(urlObject);
      if (urlObject.b2b == true) {
        setuserId((prevState) => ({
          ...prevState,
          agencyId: urlObject.agency.agencyId,
        }));
      } else if (urlObject.b2bUser == true) {
        setuserId((prevState) => ({
          ...prevState,
          agencyUserId: urlObject.agencyUser.agencyUserId,
        }));
      } else {
        setuserId((prevState) => ({
          ...prevState,
          customerId: urlObject.customer.customerId,
        }));
      }
    }
  }, []);

  const [comboPack, setcomboPack] = useState([]);
  const [loading, setloading] = useState(false);

  const getcomboList = () => {
    axios
      .post(requests.getComboOfferList, { currencyCode: "AED", platformId: 1 })
      .then((res) => {
        // console.log(res.data);
        let temp = res.data.filter((item) => item.comboOfferId == id);

        setcomboPack(temp);
        // console.log(temp[0].bannerImageFile);

        setloading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <TopNav />
      {loading == true ? (
        <>
          <DynamicBanner
            imgUrl={requests.imgpath + comboPack[0].bannerImageFile}
            title={comboPack[0].comboName}
          />
          <div className="tour-details-area mg-top--70">
            <div className="tour-details-gallery">
              <div className="container-bg bg-dark-blue">
                <div className="container">
                  <div className="gallery-filter-area row">
                    <div className="gallery-sizer" />
                    {/* gallery-item */}
                    <div className="tp-gallery-item ">
                      <div className="tp-gallery-item-img">
                        <div className="thumbnails"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-3 col-lg-4">
                      <div className="details">
                        <p className="location mb-0">
                          <i className="fa fa-map-marker" />
                          Dubai
                        </p>
                        <h4 className="title">{comboPack[0].comboName}</h4>
                      </div>
                    </div>
                    <div className="col-xl-9 col-lg-8">
                      <div className="book-list-warp">
                        <p className="book-list-content">
                          Just booked! Get your spot before it's too late.
                        </p>
                        {login === "yes" && (
                          <>
                            <div
                              className="tp-price-meta"
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              <p>Offer Price</p>
                              <h2>
                                {userId.agencyId != 0 && userId.agencyId != 0
                                  ? comboPack[0].offerB2bAdultPrice
                                  : comboPack[0].offerPrice}
                                &nbsp;&nbsp;&nbsp;
                                <small>AED</small>
                              </h2>
                              <h2></h2>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div className="tp-price-meta">
                              <p>Reguler Price</p>
                              <h2
                                style={{
                                  textDecoration: "line-through",
                                }}
                              >
                                {comboPack[0].actualPrice}&nbsp;&nbsp;&nbsp;
                                <small>AED</small>
                              </h2>
                              <h2></h2>
                            </div>{" "}
                            &nbsp;&nbsp;&nbsp;
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <Row>
                <Col sm={8}>
                  <div className="tour-details-wrap">
                    <h4 className="single-page-small-title">
                      {comboPack[0].comboName}
                    </h4>
                    <p>
                      {" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: comboPack[0].comboDescription,
                        }}
                      />
                    </p>
                  </div>
                </Col>
                <Col sm={4}>
                  {comboPack && (
                    <AttBookingForm
                      id={id}
                      loginData={loginData}
                      offerPrice={comboPack[0].offerPrice}
                      offerChildPrice={comboPack[0].offerChildPrice}
                      offerB2bAdultPrice={comboPack[0].offerB2bAdultPrice}
                      offerB2bChildPrice={comboPack[0].offerB2bChildPrice}
                      userId={userId}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </>
      ) : null}

      <Subscribe />
      <Footer />
    </>
  );
};

export default reduxForm({
  form: "ComboDetails",
  //   validate,
})(ComboDetails);

const Expocontent = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <>
      <div
        className="video-area tp-video-area pd-top-110"
        style={{ backgroundImage: "url(" + publicUrl + "assets/img/bg/7.png)" }}
      >
        <div className="container viaje-go-top">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 align-self-center wow animated fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div className="section-title mb-lg-0 mb-4 text-center text-lg-left">
                <h2 className="title">1 October 2021 – 31 March 2022</h2>
                <p>
                  Join the Marking of a New World Expo 2020 is the first Expo to
                  be held in the Middle East, Africa and South Asia (MEASA)
                  region. Opening its doors on October 1st 2021, this iconic
                  site which is twice the size of Monaco, will host more than
                  190 countries, showcasing the best in technology, innovation,
                  arts, culture, music, food and architecture for 6 months until
                  March 31st, 2022. This “not to be missed event” will be the
                  largest event held in the region.
                </p>
                {/* <Link className="btn btn-yellow" to="/about">
                  <span>
                    Read More
                    <i className="la la-arrow-right" />
                  </span>
                </Link> */}
              </div>
            </div>
            <div
              className="col-xl-5 col-lg-6 offset-xl-1 wow animated fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div className="video-popup-wrap">
                <div className="thumb">
                  <img src={publicUrl + "assets/img/expo.jpg"} alt="video" />
                </div>
                <div className="video-popup-btn">
                  <a
                    href="https://www.youtube.com/watch?v=Rb5m8nT7meo"
                    className="video-play-btn mfp-iframe"
                  >
                    <i className="fa fa-play" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /> <br /> <br />
        <div className="container">
          <div className="tour-details-wrap">
            <h4 className="single-page-small-title">
              EXPO 2020 DUBAI
              <br />
              1 October 2021 – 31 March 2022
              <br />
              Join the Marking of a New World
            </h4>
            <p>
              Expo 2020 is the first Expo to be held in the Middle East, Africa
              and South Asia (MEASA) region. Opening its doors on October 1st
              2021, this iconic site which is twice the size of Monaco, will
              host more than 190 countries, showcasing the best in technology,
              innovation, arts, culture, music, food and architecture for 6
              months until March 31st, 2022. This “not to be missed event” will
              be the largest event held in the region.
            </p>
          </div>
          <br />
          <div className="tour-details-wrap">
            <h4 className="single-page-small-title">
              HIGHLIGHTS OF THE EXPO 2020 EVENTS
              <br />
              Explore the world’s most inventive ideas
              <br />
            </h4>
            <p>
              Discover ideas and innovations that will shape and improve the
              future of the people and planet. Reimagine a better future,
              discover life-changing innovations and explore everything we can
              be, every day for six months.
            </p>
            <br />
            <h4>Gather with the world in one place </h4>
            <p>
              Join the most global Expo ever, offering unrivalled cultural
              experiences from more than 190 countries. Meet innovators and
              entertainers, and taste flavors from around the globe at 100+F&B
              outlets, every day for six months.
            </p>
            <br />
            <h4>Witness the world’s most inspiring architecture </h4>
            <p>
              Visit the world’s most visually striking and emotionally inspiring
              architecture from more than 190 countries.
            </p>
            <br />
            <h4>Enjoy the words greatest show </h4>
            <p>
              Be mesmerized by the most breathtaking entertainment and events
              that include concerts, theatre and sports brought together during
              a six-month spectacle. There will always be something to get
              excited about, with a jam-packed programme of live performances
              including world-famous stars, comedy greats, local talent and
              touring attractions. From roaming dance entertainment, acrobats
              and street performers to poetry slams and theatrical performances,
              enjoy cultural experiences fromm five continents that will excite
              your senses and touch your heart.{" "}
            </p>
          </div>
          <div className="tour-details-wrap">
            <h4 className="single-page-small-title">EXPO 2020 PACKAGES</h4>
            <p>
              <ul>
                <li>1 Day Itinerary</li>
                <li>Multiday Itineraries</li>
                <li>Family Tours</li>
                <li>Thematic Tours</li>
              </ul>
            </p>
          </div>

          <div className="tour-details-wrap">
            <h4 className="single-page-small-title">
              COVID-19 SAFETY MEASURES AT EXPO 2020 DUBAI{" "}
            </h4>
            <p>
              Expo 2020 Dubai follows guidance from the UAE Ministry of Health
              and Prevention and Dubai Health Authority, and the latest
              information and advice from the World Health Organization.
            </p>
            <h4>Keeping you safe: measure and precautions </h4>
            <p>
              <ul>
                <li>Thermal cameras at arrival points</li>
                <li>
                  Face masks to be worn at all times by visitors and staff
                </li>
                <li>Two-meter social distancing regulations</li>
                <li>
                  Regular cleaning and sanitization of the venues and common
                  areas
                </li>
                <li>
                  Hand-sanitizer station positioned at regular intervals around
                  the site
                </li>
                <li>
                  Appropriate facilities and specialist staff to manage any
                  potential medial issues on site
                </li>
              </ul>
            </p>
            <br />
            <h4>Ensuring social distancing </h4>

            <p>
              <ul>
                <li>
                  Capacity limit to control the maximum number of people
                  permitted in venues
                </li>
                <li>
                  Floor markings to encourage social distancing in areas where
                  visitors could queue or congregate
                </li>
                <li>
                  Signage and posters throughout the site that reinforce the
                  importance of social distancing
                </li>
                <li>
                  Designated staff who will be responsible for monitoring and
                  regulating social distancing across the site
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* <CoundownTimer/> */}
    </>
  );
};

export default Expocontent;

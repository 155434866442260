import axios from "axios";
import React, { useEffect, useState } from "react";
import encryptStorage from "../utils/Encryptstorage";
import requests from "../utils/Requests";
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
const DatedTktPayment = () => {
  const [urlObj, seturlObj] = useState([]);
  const [submitData, setsubmitData] = useState([]);
  const [bookdetails, setbookdetails] = useState([]);
  const [isloading, setisloading] = useState(true);
  useEffect(() => {
    const url = encryptStorage.getItem("url"); //obj
    let urlObject = JSON.parse(url);
    seturlObj(urlObject);

    const firstSubmitForBooking = encryptStorage.getItem(
      "firstSubmitForBooking"
    ); //obj
    let submitObj = JSON.parse(firstSubmitForBooking);
    setsubmitData(submitObj);
    //  console.log("submitObj 1111111111111", submitObj);

    const obj = encryptStorage.getItem("DFuck");
    let bookingObject = JSON.parse(obj);
    setbookdetails(bookingObject);
    // console.log("bookingObject", bookingObject);

    setTimeout(function () {
      axios
        .post(requests.saveBurjTicket, submitObj)
        .then((response) => {
          encryptStorage.setItem("urlTwo", response.data);
          setisloading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1000);
  }, []);

  let attname = sessionStorage.getItem("attname");
  let history = useHistory();
  const payNow = () => {
    window.location.replace(urlObj.paymentUrl);
  };
  return (
    <>
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}>
          <div className="container rounded bg-white">
            <div className="d-flex justify-content-end">
              <a class="cancel com-color" onClick={history.goBack}>
                Cancel and return to website
              </a>
            </div>
            <div className="d-flex justify-content-center">
              <h4 className="single-page-small-title">Your Booking Details</h4>
            </div>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Park Name </h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? <p>Please Wait</p> : attname}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Booking Customer Name</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookCustomerName
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Your Email</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookCustomerEmail
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Contact</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookMobileNumber
                  )}
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Ticket Type</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">{ctkttype}</div>
              </Col>
            </Row> */}
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Number Of Adult</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookNofAdult
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Number Of Child</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {bookdetails.bookNofChild == null ? (
                    0
                  ) : isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookNofChild
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Date</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookTravellDate
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Time</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    submitData.startDateTime
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Total Amount</h6>
                </div>
              </Col>

              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookTotal
                  )}
                </div>
              </Col>
            </Row>
            <br />
            {/* {showErrMsg && <Alert color={alertStyle}>{errMessage}</Alert>} */}

            <Row>
              <Col xs={6}></Col>

              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <div class="buttons">
                    {" "}
                    {isloading == true ? null : (
                      <button
                        class="btn btn-success btn-block"
                        type="submit"
                        onClick={() => payNow()}
                      >
                        Proceed to payment
                      </button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={2}></Col>
      </Row>
    </>
  );
};

export default DatedTktPayment;

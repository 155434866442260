/** @format */

import axios from "axios";
import React, { useEffect, useState } from "react";
import DynamicBanner from "../pages/global-components/dynamic_banner";
import TopNav from "../pages/global-components/top_nav";
import encryptStorage from "../utils/Encryptstorage";
import requests from "../utils/Requests";
import { Row, Col, CardBody } from "reactstrap";
import { useHistory, useParams } from "react-router";
import { Carousel } from "react-responsive-carousel";
import AttBookingForm from "./AttBookingForm";
const AttractionDetails = () => {
  const { attId } = useParams();
  useEffect(() => {
    getAttractionDetails();
    getBannerAddons();
    getFormAddons();
    encryptStorage.removeItem("DFuck");
    encryptStorage.removeItem("url");
    encryptStorage.removeItem("suckapi");
    encryptStorage.removeItem("firstSubmitForBooking");
    window.scrollTo(0, 0);
    let login = sessionStorage.getItem("isLoggedIn");

    if (login == "yes") {
      setloadForm(false);
      const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      let urlObject = JSON.parse(enl);
      console.log("user Login Data", urlObject);
      setlginUserData(urlObject);
      setloadForm(true);
      if (urlObject.b2b == true) {
      } else if (urlObject.b2bUser == true) {
      } else if (urlObject.b2c == true) {
      }
    }
  }, []);
  const [lginUserData, setlginUserData] = useState("");
  const [attractionDetail, setattractionDetail] = useState("");
  const [bannerAddon, setbannerAddon] = useState([""]);
  const [formAddon, setformAddon] = useState([""]);
  const [loadForm, setloadForm] = useState(false);
  const getAttractionDetails = async () => {
    setloadForm(false);
    await axios
      .post(requests.getAttractionDetails, {
        attractionsId: attId,
        agencyId: 0,
        agencyUserId: 0,
      })
      .then((response) => {
        console.log("response", response.data);
        setattractionDetail(response.data);
        setloadForm(true);
      })
      .catch(() => {});
  };
  const getBannerAddons = () => {
    axios
      .post(requests.getaddonbannerlist, { attractionId: attId })
      .then((response) => {
        setbannerAddon(response.data);
      })
      .catch((err) => {});
  };
  const getFormAddons = () => {
    setloadForm(false);
    axios
      .post(requests.setaddonformlist, { attractionId: attId })
      .then((response) => {
        setformAddon(response.data);
        setloadForm(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <TopNav />
      <DynamicBanner
        imgUrl={requests.imgpath + attractionDetail.attBannerImage}
        title={attractionDetail.attName}
      />
      <div className="tour-details-area mg-top--70">
        <div className="tour-details-gallery">
          <div className="container-bg bg-dark-blue">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-4">
                  <div className="details">
                    <p className="location mb-0">
                      <i className="fa fa-map-marker" />
                      {attractionDetail.attCity}
                    </p>
                    <h4 className="title">{attractionDetail.attName}</h4>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8">
                  <div className="book-list-warp">
                    <p className="book-list-content">
                      Just booked! Get your spot before it's too late.
                    </p>
                    <div className="tp-price-meta">
                      <Row>
                        <Col>
                          <p>
                            Adult Price <h2>{attractionDetail.adultPrice}</h2>
                          </p>
                          &nbsp;&nbsp;&nbsp;<small>AED</small>
                        </Col>
                        <Col>
                          <p>
                            Child Price <br />
                            <h2>{attractionDetail.childPrice}</h2>
                          </p>
                          &nbsp;&nbsp;&nbsp;<small>AED</small>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <ul className="tp-list-meta border-tp-solid">
                    {bannerAddon != "" &&
                      bannerAddon.map((bannerAddon, index) => (
                        <li key={index}>
                          <i className={bannerAddon.addonIcon} />
                          {bannerAddon.addonType} &nbsp; - &nbsp;
                          {bannerAddon.addonValue}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {attractionDetail == "" ? (
            <p>Loading</p>
          ) : (
            <>
              <Row>
                <Col sm={8}>
                  <CardBody>
                    <Carousel autoPlay={true} infiniteLoop={true}>
                      {attractionDetail.filesStorage.map((tourImage, index) => (
                        <div className="tp-gallery-item col-lg-12 " key={index}>
                          <div className="tp-gallery-item-img">
                            <a href="#" data-effect="mfp-zoom-in">
                              <img
                                height="300"
                                width="100%"
                                src={requests.imgpath + tourImage.fileName}
                                alt="image"
                              />
                            </a>
                          </div>
                        </div>
                      ))}
                    </Carousel>

                    <div className="tour-details-wrap">
                      <h4 className="single-page-small-title">
                        {attractionDetail.attName}
                      </h4>
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: attractionDetail.attDescription,
                          }}
                        />
                      </p>
                    </div>
                  </CardBody>
                </Col>
                <Col sm={4}>
                  {loadForm === true && (
                    <AttBookingForm
                      attId={attId}
                      formAddon={formAddon}
                      lginUserData={lginUserData}
                      attractionDetail={attractionDetail}
                    />
                  )}
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AttractionDetails;

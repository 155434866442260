import React, { useEffect, useState } from "react";
import axios from "axios";
import requests from "../../utils/Requests";
const AgentAcc = ({ agentData }) => {
  useEffect(() => {
    if (agentData != "") {
     
    
    }
  });


  return (
    <>
      <br></br>
      <div
        style={{
          marginTop: "65px",
          backgroundColor: "aliceblue",
          marginBottom: "-10px",
        }}
      >
        <div className="container">
          <div className="d-flex justify-content-end">
            <p> <b>Credit Limit :</b> {agentData.agency.creditLimit}</p> &nbsp;&nbsp;&nbsp;
            <p><b>Credit Utilized :</b> {agentData.agency.creditUtilized}</p> &nbsp;&nbsp;&nbsp;
            <p><b>Current Balance :</b> {agentData.agency.currentBalance}</p> &nbsp;&nbsp;&nbsp;

            
          </div>
        </div>
      </div>
    </>
  );
};
export default AgentAcc;

import { BrowserRouter, Switch, Route } from "react-router-dom";
import RouteWithSubRoute from "./utils/route_with_sub_route";
import routes from "./routes/routes";
import unauthroutes from "./routes/unauthroutes";
import NotFound from "./pages/not_found_page";
import React from "react";

const WebIndex = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoute key={i} {...route} />
          ))}
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default WebIndex;

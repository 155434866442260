import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import encryptStorage from "../../utils/Encryptstorage";
import CommonBanner from "../global-components/common_banner";
import DynamicBanner from "../global-components/dynamic_banner";
import Footer from "../global-components/footer";
import Subscribe from "../global-components/subscribe";
import TopNav from "../global-components/top_nav";
import VisaForm from "./VisaForm";
const VisaBooingForm = () => {
  let publicUrl = process.env.PUBLIC_URL + "/assets/img/visa/";
  
  return (
    <>
      <TopNav />
      {/* <DynamicBanner title="Visa-Services" imgUrl="visa_Banner.jpg" /> */}
      <CommonBanner title="Visa-Services" imgUrl="visa_Banner.jpg" />
      <div className="tour-list-area pd-top-120 viaje-go-top">
        <div className="container">
          <Row>
            <Col xs={8}>
              <h3 className="single-page-small-title">Book Now</h3>
              <VisaForm />
            </Col>
            <Col xs={4}>
              <Card>
                <CardBody>
                  <h3 className="single-page-small-title">
                    Image Uploading Format
                  </h3>
                  <h5 className="single-page-small-title">
                    Passport First Page
                  </h5>
                  <img src={publicUrl + "visaFirstPage.jpg"} />

                  <h5 className="single-page-small-title">
                    Passport Last Page
                  </h5>
                  <img src={publicUrl + "visaLastPage.jpg"} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Subscribe />
      <Footer />
    </>
  );
};

export default VisaBooingForm;

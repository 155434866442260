import { Link } from "react-router-dom";
import requests from "../../utils/Requests";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Row, Col } from "reactstrap";
const OfferAndDiscount = ({ attData, login }) => {
  let isLoggedIn = sessionStorage.getItem("isLoggedIn");
  let publicUrl = process.env.PUBLIC_URL + "/";
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="offer-area pd-top-70">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title text-center">
                <h2 className="title">Special offers &amp; Discounts</h2>
                <p>
                  We have the answers to your travel needs. Call us now, Invest
                  in unforgettable travel experiences ──✈─
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="destinations-list-slider-bg">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 offset-xl-1 order-lg-12">
                <div className="destinations-list-slider">
                  <Carousel
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    //  autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                  >
                    {attData.map((attraction, index) => (
                      <div key={index}>
                        <div className="d-list-slider-item">
                          <div className="single-destinations-list text-center">
                            <div className="thumb">
                              <span className="d-list-tag">Special Offer</span>

                              <img
                                src={
                                  requests.imgpath +
                                  attraction.attThumbnailImage
                                }
                                alt="list"
                              />

                              <div className="d-list-btn-wrap">
                                <div className="d-list-btn viaje-go-top">
                                  <Link
                                    className="btn btn-yellow"
                                    to={`tour-pack-details/${attraction.attractionsId}`}
                                  >
                                    Book Now <i className="fa fa-paper-plane" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="details">
                              <p
                                className="title"
                                style={{
                                  color: "#071c55",
                                }}
                              >
                                {attraction.attName}
                              </p>
                              <p className="content"></p>
                              {/* <ul className="tp-list-meta border-bt-dot">
                                <li>
                                  <i className="fa fa-calendar-o" /> 8oct
                                </li>
                                <li>
                                  <i className="fa fa-clock-o" /> 4 days
                                </li>
                                <li>
                                  <i className="fa fa-star" /> 4.3
                                </li>
                              </ul> */}{" "}
                              {isLoggedIn === "yes" && (
                                <div className="tp-price-meta tp-price-meta-cl">
                                  <Row>
                                    <Col>
                                      <p>
                                        Adult Price{" "}
                                        <h2>{attraction.adultPrice}</h2>
                                      </p>{" "}
                                      &nbsp;&nbsp;&nbsp;<small>AED</small>
                                    </Col>
                                    <Col>
                                      <p>
                                        Child Price <br />
                                        <h2>{attraction.childPrice}</h2>
                                      </p>{" "}
                                      &nbsp;&nbsp;&nbsp;<small>AED</small>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
              <div className="col-lg-2 align-self-center order-lg-11">
                {/* <img src="https://st.depositphotos.com/1144373/1352/v/950/depositphotos_13520755-stock-illustration-best-offer-label-with-arrows.jpg" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferAndDiscount;

/** @format */

import React, { useEffect, useState } from "react";
import { reduxForm, Form, Field } from "redux-form";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import RenderField from "../form-components/RenderField";
import requests from "../utils/Requests";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import encryptStorage from "../utils/Encryptstorage";
import { usData } from "../utils/RdData";
const validate = (values) => {
  const errors = {};
  if (values.BookForAgent) {
    if (!values.agentIdForBookfromSuperAdmin) {
      errors.agentIdForBookfromSuperAdmin = "Required";
    }
  }
  if (!values.bookCustomerName) {
    errors.bookCustomerName = "Required ";
  }
  if (!values.bookCustomerEmail) {
    errors.bookCustomerEmail = "Required ";
  }
  if (!values.bookMobileNumber) {
    errors.bookMobileNumber = "Required";
  }
  if (!values.passengertktType) {
    errors.passengertktType = "Required";
  }
  if (!values.bookNofAdult) {
    errors.bookNofAdult = "Required ";
  }

  if (!values.bookTravellDate) {
    errors.bookTravellDate = "Required ";
  }
  return errors;
};

const AttBookingForm = (props) => {
  // Here get all Form Addon, UserLogin Data from parent and get all tickets from api
  const xmlResponce = usData();

  const hConfig = {
    headers: {
      "access-Key": xmlResponce,
    },
  };
  const [billAmount, setbillAmount] = useState({
    finalAmount: 0,
    adultCountPrize: "",
    childCountPrize: "",
    adulttktPrize: "",
    childtktPrice: "",
    addonPrize: "",
    offerPrice: "",
    offerChildPrice: "",
    offerB2bAdultPrice: "",
    offerB2bChildPrice: "",
  });
  const history = useHistory();
  const [totalamount, settotalamount] = useState(0);
  const [userId, setuserId] = useState([]);
  const [splError, setsplError] = useState();
  useEffect(() => {
    encryptStorage.removeItem("DFuck");
    encryptStorage.removeItem("url");
    setuserId(props.userId);

    setbillAmount((prevState) => ({
      ...prevState,
      offerPrice: props.offerPrice,
      offerChildPrice: props.offerChildPrice,
      offerB2bAdultPrice: props.offerB2bAdultPrice,
      offerB2bChildPrice: props.offerB2bChildPrice,
    }));
    props.initialize({ comboId: props.id });

    if (loginData.b2b == true) {
      setsplError(loginData.agency.agencyPassword);
    } else if (loginData.b2bUser == true) {
      setsplError(loginData.agencyUser.userPassword);
    } else {
    }

    // setformAddon(props.formAddon);
    // setloginData(props.lginUserData);
    // setattractionDetail(props.attractionDetail);
    // getTicketForAttraction(props.attId);

    // if (props.lginUserData != "") {
    //   if (props.lginUserData.b2b == true) {
    //     if (props.lginUserData.agency.superAdmin == true) {
    //       getAllAgentList();
    //     }
    //     settmpObjects((prevState) => ({
    //       ...prevState,
    //       agencyId: props.lginUserData.agency.agencyId,
    //     }));
    //     if (props.attractionDetail.attConnectWithApi == true) {
    //       setoptionSelect((prevState) => ({
    //         ...prevState,
    //         showTktType: false,
    //         bookTicketType: 2,
    //       }));
    //     }
    //   } else if (loginData.b2bUser == true) {
    //     settmpObjects((prevState) => ({
    //       ...prevState,
    //       agencyId: props.lginUserData.agencyUser.agencyUserId,
    //     }));
    //     if (props.attractionDetail.attConnectWithApi == true) {
    //       setoptionSelect((prevState) => ({
    //         ...prevState,
    //         showTktType: false,
    //         bookTicketType: 1,
    //       }));
    //     }
    //   } else {
    //     //user login
    //     if (props.attractionDetail.attConnectWithApi == true) {
    //       setoptionSelect((prevState) => ({
    //         ...prevState,
    //         bookTicketType: 2, // 1-> Open ticked from local data base ticket 2-> Dated Ticket from Api 3-> option(show radio button)
    //       }));
    //     }
    //   }
    // } else {
    //   // guest (not login)
    //   if (props.attractionDetail.attConnectWithApi == true) {
    //     setoptionSelect((prevState) => ({
    //       ...prevState,
    //       bookTicketType: 2, // 1-> Open ticked from local data base ticket 2-> Dated Ticket from Api 3-> option(show radio button)
    //     }));
    //   }
    // }
  }, []);

  const { handleSubmit } = props;
  const tempOptionObj = [{ name: "select", value: 1 }];
  const [attractionDetail, setattractionDetail] = useState("");
  const [formAddon, setformAddon] = useState([]);
  const [loginData, setloginData] = useState("");
  const [paymentMode, setpaymentMode] = useState(1);

  const [bookingAddon, setbookingAddon] = useState([]);
  const [formError, setformError] = useState({});
  const [tktCount, settktCount] = useState({
    bookNofAdult: 0,
    bookNofChild: 0,
  });
  const [optionSelect, setoptionSelect] = useState({
    bookedCredit: 1,
    bookTicketType: 1,
    showTktType: true,
  });
  const [DropDown, setDropDown] = useState({
    agentList: [{}],
    ticketList: [{}],
    apiticketList: [{}],
  });
  const [tmpObjects, settmpObjects] = useState({
    apiTicket: [],
  });

  const logout = (e) => {
    sessionStorage.clear();
    window.location.reload();
    // console.log("clear");
    window.location.href = "/";
  };
  const getTicketForAttraction = (attId) => {
    axios
      .post(requests.getTicketTypeListByAttraction, { ttAttractionId: attId })
      .then((response) => {
        let tickets = [...DropDown.ticketList];
        let apitickets = [...DropDown.apiticketList];
        tickets.length = 0;
        tickets.push({
          name: "SELECT TICKET TYPE",
          value: "",
        });
        apitickets.length = 0;
        apitickets.push({
          name: "SELECT TICKET TYPE",
          value: "",
          eventtypeId: "",
          resourceID: "",
        });
        for (let i = 0; i < response.data.length; i++) {
          if (
            response.data[i].resourceID == null ||
            (response.data[i].resourceID == 0 &&
              response.data[i].eventtypeId == null) ||
            response.data[i].eventtypeId == 0
          ) {
            tickets.push({
              name: response.data[i].ttTicketType,
              value: response.data[i].ticketTypeId,
            });
          } else {
            apitickets.push({
              name: response.data[i].ttTicketType,
              value: response.data[i].ticketTypeId,
              eventtypeId: response.data[i].eventtypeId,
              resourceID: response.data[i].resourceID,
            });
          }
        }

        setDropDown((prevState) => ({
          ...prevState,
          ticketList: tickets,
          apiticketList: apitickets,
        }));
      })
      .catch(() => {});
  };

  // Add addon Value into another state and also check addon avilble or not on api
  const addonValues = (e) => {
    let val = e.target.id;
    let newprice = e.target.value;
    let tempAddon = [...bookingAddon];
    if (e.target.checked == true) {
      let finalprice = Number(billAmount.addonPrize) + Number(newprice);

      const tempData = {
        bookingAddon: val,
        secretKey: requests.apiKey,
      };
      axios
        .post(requests.checkAddonBarcodeAvailability, tempData)
        .then((response) => {
          if (response.data.errorCode == 600) {
            setformError((prevState) => ({
              ...prevState,
              AddonAvilErr: "Addon Not Avilable",
            }));
          } else if (response.data.errorCode == 200) {
            // settempErr(false)
            let tempEle = { addonId: val };
            tempAddon.push(tempEle);
            setbookingAddon(tempAddon);

            setbillAmount((prevState) => ({
              ...prevState,
              addonPrize: Number(billAmount.addonPrize) + Number(newprice),
              finalAmount:
                Number(billAmount.addonPrize) +
                Number(newprice) +
                Number(billAmount.adultCountPrize) +
                Number(billAmount.childCountPrize),
            }));
          }
        })
        .catch((error) => {
          setformError((prevState) => ({
            ...prevState,
            AddonapiError: "Some Technical problem",
          }));
        });
    } else {
      setformError((prevState) => ({
        ...prevState,
        AddonapiError: "",
      }));
      let temp = bookingAddon.filter((item) => item.addonId != val);
      setbookingAddon(temp);

      setbillAmount((prevState) => ({
        ...prevState,
        addonPrize: Number(billAmount.addonPrize) - Number(newprice),
        finalAmount:
          Number(billAmount.addonPrize) -
          Number(newprice) +
          (Number(billAmount.adultCountPrize) +
            Number(billAmount.childCountPrize)),
      }));
    }
  };

  // billAmountCalculation(0,0)

  const checkapiTimeSlot = (dateVal) => {
    if (tmpObjects.apiTicket != "") {
      setformError((prevState) => ({
        ...prevState,
        timeSlotError: "",
      }));
      axios
        .post(requests.getBurjTimeSlotWithRates, {
          bookingDate: dateVal,
          eventTypeId: tmpObjects.apiTicket[0].eventTypeId,
          resourceId: tmpObjects.apiTicket[0].resourceId,
          secretKey: requests.apiKey,
        })
        .then((response) => {})
        .catch((error) => {
          setformError((prevState) => ({
            ...prevState,
            timeSlotapiError: "Some Technical problem",
          }));
        });
    }
  };

  const billAmountCalculation = (countName, countValue) => {
    let offerPrice = billAmount.offerPrice;
    let finalbillAmount,
      tempChildRate = 0,
      tempAdultRate = 0;

    if (countName == "adult") {
      tempAdultRate = Number(offerPrice) * Number(tktCount.bookNofAdult);
    } else if (countName == "child") {
      tempAdultRate = Number(offerPrice) * Number(tktCount.bookNofAdult);
    }

    settotalamount(Number(tempChildRate) + Number(tempAdultRate));

    setbillAmount((prevState) => ({
      ...prevState,
      finalAmount: finalbillAmount,
    }));

    // finalAmount: 0,
    // adultCountPrize:'',
    // childCountPrize:'',
    // adulttktPrize:'',
    // childtktPrice:''
  };
  const getAllAgentList = () => {
    axios
      .post(requests.getagencylist, {
        attractionsId: 1,
        secretKey: requests.apiKey,
      })
      .then((response) => {
        let agent = [...DropDown.agentList];
        for (let i = 0; i < response.data.length; i++) {
          agent.push({
            label: response.data[i].agencyName,
            value: response.data[i].agencyId,
          });
        }
        setDropDown((prevState) => ({ ...prevState, agentList: agent }));
      })
      .catch(() => {});
  };
  const getTicketPrize = (tktId, apiId) => {
    let tempValue = [];
    if (tktId == 0) {
      setbillAmount((prevState) => ({
        ...prevState,
        finalAmount: "",
        adultCountPrize: "",
        childCountPrize: "",
      }));
    }
    setbillAmount((prevState) => ({
      ...prevState,
      adulttktPrize: "",
      childtktPrice: "",
    }));

    // let temp = apiTicketType.filter((item) => item.value == val);
    const tempData = {
      ticketTypeId: tktId,
      agencyId: tmpObjects.agencyId,
      agencyUserId:
        loginData.b2bUser == true ? loginData.agencyUser.agencyUserId : 0,
      attractionsId: props.attId,
    };

    if (tktId != 0) {
      if (apiId == 2) {
        const apiTicketFilter = DropDown.apiticketList.filter(
          (apitkt) => apitkt.value == tktId
        );
        tempValue.push({
          eventtypeId: apiTicketFilter[0].eventtypeId,
          resourceID: apiTicketFilter[0].resourceID,
        });
        settmpObjects((prevState) => ({
          ...prevState,
          apiTicket: tempValue,
        }));
      } else {
        settmpObjects((prevState) => ({
          ...prevState,
          apiTicket: tempValue,
        }));
        axios
          .post(requests.getAttractionTicketTypePrice, tempData)
          .then((response) => {
            if (response.data.errorCode == 505) {
              setformError((prevState) => ({
                ...prevState,
                adultTktStatus: "Ticket Not Available", //errorMessage
                childTktStatus: "Ticket Not Available",
              }));
            } else if (
              response.data.errorCode == 504 ||
              response.data.errorMessage == "Child Ticket Not Available"
            ) {
              setformError((prevState) => ({
                ...prevState,
                childTktStatus: response.data.errorMessage, //errorMessage
              }));
              if (loginData.b2b == true || loginData.b2bUser == true) {
                if (response.data.b2bAdultDisPrice != 0) {
                  setbillAmount((prevState) => ({
                    ...prevState,
                    adulttktPrize: response.data.b2bAdultDisPrice,
                  }));
                } else {
                  setbillAmount((prevState) => ({
                    ...prevState,
                    adulttktPrize: response.data.b2bAdultPrice,
                  }));
                }
              } else {
                setbillAmount((prevState) => ({
                  ...prevState,
                  adulttktPrize: response.data.b2cAdultPrice,
                }));
              }
            } else {
              if (loginData.b2b == true || loginData.b2bUser == true) {
                if (response.data.b2bAdultDisPrice != 0) {
                  setbillAmount((prevState) => ({
                    ...prevState,
                    adulttktPrize: response.data.b2bAdultDisPrice,
                  }));
                }
                if (response.data.b2bChildDisPrice != 0) {
                  setbillAmount((prevState) => ({
                    ...prevState,
                    childtktPrice: response.data.b2bChildDisPrice,
                  }));
                }
                if (
                  response.data.b2bAdultDisPrice != 0 &&
                  response.data.b2bChildDisPrice == 0
                ) {
                  setbillAmount((prevState) => ({
                    ...prevState,
                    adulttktPrize: response.data.b2bAdultDisPrice,
                    childtktPrice: response.data.b2bChildPrice,
                  }));
                }
                if (
                  response.data.b2bAdultDisPrice == 0 &&
                  response.data.b2bChildDisPrice != 0
                ) {
                  setbillAmount((prevState) => ({
                    ...prevState,
                    adulttktPrize: response.data.b2bAdultPrice,
                    childtktPrice: response.data.b2bChildDisPrice,
                  }));
                } else {
                  setbillAmount((prevState) => ({
                    ...prevState,
                    adulttktPrize: response.data.b2bAdultPrice,
                    childtktPrice: response.data.b2bChildPrice,
                  }));
                }
              } else {
                setbillAmount((prevState) => ({
                  ...prevState,
                  adulttktPrize: response.data.b2cAdultPrice,
                  childtktPrice: response.data.b2cChildPrice,
                }));
              }
            }
          })
          .catch(() => {});
      }
    } else {
      setbillAmount((prevState) => ({
        ...prevState,
        adulttktPrize: "",
        childtktPrice: "",
      }));
    }
  };
  const bookForAgent = (values) => {
    console.log("userId", userId);
    //Number(tktCount.bookNofAdult) * Number(billAmount.offerPrice) + Number(tktCount.bookNofChild)* Number(billAmount.offerChildPrice)
    // {
    //   "comboId": 16,
    //   "bookCustomerName": "Raman Macchavel",
    //   "bookMobileNumber": "9952411164",
    //   "bookCustomerEmail": "raman.m16@gmail.com",
    //   "bookPaymentMode": 2,
    //   "bookTravellDate": "2022-07-29",
    //   "bookTotal": 100,
    //   "bookNofAdult": 1,
    //   "bookNofChild": 0,
    //   "bookB2bId": 158,
    //   "apiTicket": "false",
    //   "bookedByBackOffice": "false",
    //   "secretKey": "uZFEucIHAbqvgT7p87qC4Ms4tjqG34su"
    // }

    let uid = 0,
      agentId = 0,
      auid = 0,
      bookingtotal = 0;

    if (userId.agencyId != 0) {
      agentId = userId.agencyId;
    } else if (userId.agencyUserId != 0) {
      auid = userId.agencyUserId;
    } else {
      uid = userId.customerId;
    }

    if (userId.agencyId != 0 || userId.agencyUserId != 0) {
      //console.log("login b2b");
      bookingtotal = Number(
        tktCount.bookNofAdult * billAmount.offerB2bAdultPrice +
          tktCount.bookNofChild * billAmount.offerB2bChildPrice
      );
    } else {
      bookingtotal =
        Number(tktCount.bookNofAdult) * Number(billAmount.offerPrice) +
        Number(tktCount.bookNofChild) * Number(billAmount.offerChildPrice);
    }

    const sumbmitData = {
      comboId: props.id,
      superAdminId: 0,
      bookCustomerName: values.bookCustomerName,
      bookMobileNumber: values.bookMobileNumber,
      bookCustomerEmail: values.bookCustomerEmail,
      bookPaymentMode: paymentMode,
      bookTravellDate: values.bookTravellDate,
      bookTotal: bookingtotal,
      bookNofAdult: values.bookNofAdult,
      bookNofChild: values.bookNofChild,
      bookB2bId: userId.agencyId,
      bookB2bUserId: userId.agencyUserId,
      bookB2cId: userId.customerId,
      apiTicket: "false",
      bookedByBackOffice: "false",
      secretKey: requests.apiKey,
      errorMessage: splError,
    };

    console.log("submitData", JSON.stringify(sumbmitData, null, 2));
    encryptStorage.setItem("DFuck", sumbmitData);

    if (uid != 0 || auid != 0 || agentId != 0) {
      if (values.bookPaymentMode == 2) {
        axios
          .post(requests.setComboBooking, sumbmitData, hConfig)
          .then((response) => {
            console.log(`${JSON.stringify(response.data, null, 2)}`);

            if (response.data.errorCode == 505) {
              //no adult tkt
              Swal.fire({
                title: "Sorry !",
                text: "Ticket Not Available",
                icon: "error",
              });
            } else if (response.data.errorCode == 504) {
              // no child tkt
              Swal.fire({
                title: "Sorry !",
                text: "Ticket Not Available",
                icon: "error",
              });
            } else if (response.data.errorCode == 100) {
              Swal.fire({
                title: "Sorry !",
                text: "Insuffecient Balance",
                icon: "error",
              });
            } else if (response.data.errorCode == 303) {
              Swal.fire({
                title: "Sorry !",
                text: "Some Technical Problem",
                icon: "error",
              });
            } else if (response.data.errorCode == 563) {
              Swal.fire({
                title: "Warning",
                text: "Another User has logged in using your Login Credentials, Please Login Again.",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Ok",
                denyButtonText: `Don't save`,
              }).then((result) => {
                if (result.isConfirmed) {
                  // download_file(fileURL, filename);
                  // setshowAlert(0);
                  logout();
                  //window.location.replace(fileURL)
                }
              });
            } else {
              const fileURL =
                // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
                "https://b2b.parmartours.com/filestorage/" +
                response.data.bookingTickPdfPath;
              const filename = response.data.bookingTickPdfPath;

              Swal.fire({
                title: "Booking Confirmed",
                text: "Thank You For Your Booking, press OK to download your e-Ticket",
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "Ok",
                denyButtonText: `Don't save`,
              }).then((result) => {
                if (result.isConfirmed) {
                  download_file(fileURL, filename);
                }
              });
            }
          })
          .catch((error) => {});
      } else {
        //goto Online payment
        const paymentData = {
          paymentAgencyId: agentId,
          paymentAgencyUserId: auid,
          paymentB2cId: uid,
          paymentCustomerName: values.bookCustomerName,
          paymentRemarks: "Test Pay",
          paymentAmount: bookingtotal,
          secretKey: requests.apiKey,
          successUrl: `https://b2b.parmartours.com/${"comboticket"}/payment-status`,
          failureUrl: `https://b2b.parmartours.com/${"comboticket"}/payment-status`,
          temporaryBookingDto: sumbmitData,
        };
        // console.log(`${JSON.stringify(paymentData, null, 2)}`);

        axios
          .post(requests.setNetworkPaymentsDetail, paymentData)
          .then((res) => {
            console.log("res", res.data);
            if (res.data != "" || res.data == null) {
              encryptStorage.setItem("url", res.data);
              window.location.replace(res.data.paymentUrl);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      //goto login
    }

    // axios
    //   .post(requests.setComboBooking, values)
    //   .then((response) => {})
    //   .catch((error) => {});
  };

  /* Helper function */
  function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }
  return (
    <>
      <div className="fixed-content">
        <Card>
          <CardHeader>
            <h3 className="single-page-small-title">Book Now </h3>
          </CardHeader>
          <CardBody>
            <Form>
              {loginData != "" &&
                loginData.b2b == true &&
                loginData.agency.superAdmin && (
                  <Field
                    name="BookForAgent"
                    component={RenderField.RendercheckboxField}
                    label="Book For Agent"
                    onChange={(e) => {
                      setoptionSelect((prevState) => ({
                        ...prevState,
                        bookForAgent: e.target.checked,
                      }));
                      settmpObjects((prevState) => ({
                        ...prevState,
                        agencyId: loginData.agency.agencyId,
                      }));
                      e.target.checked == false &&
                        getTicketForAttraction(props.attId);
                    }}
                  />
                )}
              {optionSelect.bookForAgent && (
                <>
                  <Row>
                    <Col>
                      <Field
                        name="bookedCredit"
                        component="input"
                        type="radio"
                        value="1"
                        checked={optionSelect.bookedCredit == 1 && "checked"}
                        onChange={(e) =>
                          setoptionSelect((prevState) => ({
                            ...prevState,
                            bookedCredit: e.target.value,
                          }))
                        }
                      />{" "}
                      Agent Credit
                    </Col>

                    <Col>
                      <Field
                        name="bookedCredit"
                        component="input"
                        type="radio"
                        value="2"
                        checked={optionSelect.bookedCredit == 2 && "checked"}
                        onChange={(e) =>
                          setoptionSelect((prevState) => ({
                            ...prevState,
                            bookedCredit: e.target.value,
                          }))
                        }
                      />{" "}
                      Super User Credit
                    </Col>
                  </Row>
                  <Field
                    name="agentIdForBookfromSuperAdmin"
                    label="Select Agent"
                    options={DropDown.agentList}
                    // onChange={(e) =>
                    //   setagentIdForBook(e)
                    // }
                    onChange={(e) => {
                      settmpObjects((prevState) => ({
                        ...prevState,
                        agencyId: e,
                      }));
                      getTicketForAttraction(props.attId);
                    }}
                    component={RenderField.RenderSelectField}
                  />
                </>
              )}
              <Field
                name="bookCustomerName"
                type="text"
                label="Lead Passenger Name"
                component={RenderField.RenderTextField}
              />
              <Field
                name="bookCustomerEmail"
                type="email"
                label="Email Address"
                component={RenderField.RenderTextField}
              />
              <Field
                name="bookMobileNumber"
                type="text"
                label="Contact Number"
                component={RenderField.RenderTextField}
              />
              {/* {loginData != "" &&
                loginData.b2b == true &&
                attractionDetail.attConnectWithApi == true && (
                  <>
                    <br />
                    <Row>
                      <Col>
                        <Field
                          name='openTkt'
                          component='input'
                          type='radio'
                          value='1'
                          onChange={(e) =>
                            setoptionSelect((prevState) => ({
                              ...prevState,
                              bookTicketType: "",
                              showTktType: true,
                            }))
                          }
                        />
                        Open Ticket
                      </Col>
                      <Col>
                        <Field
                          name='openTkt'
                          component='input'
                          type='radio'
                          value='2'
                          onChange={(e) =>
                            setoptionSelect((prevState) => ({
                              ...prevState,
                              bookTicketType: 2,
                              showTktType: true,
                            }))
                          }
                        />
                        Dated Ticket
                      </Col>
                    </Row>
                  </>
                )}
              {optionSelect.showTktType === true && (
                <>
                  <Field
                    name='ticketTypeId'
                    type='select'
                    customfeild={
                      optionSelect.bookTicketType == 2
                        ? DropDown.apiticketList
                        : DropDown.ticketList
                    }
                    label='Ticket Type'
                    onChange={(e) =>
                      getTicketPrize(
                        e.target.value,
                        optionSelect.bookTicketType == 2 ? 2 : 1
                      )
                    }
                    component={RenderField.renderOptionField}
                  />
                </>
              )} */}

              <Row>
                <Col>
                  <Field
                    name="bookNofAdult"
                    type="number"
                    label="Adult"
                    disabled={formError.adultTktStatus && "disabled"}
                    onChange={(e) => {
                      settktCount((prevState) => ({
                        ...prevState,
                        bookNofAdult: e.target.value,
                      }));
                      billAmountCalculation("adult", e.target.value);
                    }}
                    component={RenderField.RenderTextField}
                  />

                  {!formError.adultTktStatus && billAmount.adulttktPrize}
                  <div className="err-msg" style={{ fontSize: "0.8em" }}>
                    <span>{formError.adultTktStatus}</span>
                  </div>
                </Col>
                <Col>
                  <Field
                    name="bookNofChild"
                    type="number"
                    label="Child"
                    disabled={formError.childTktStatus && "disabled"}
                    // disabled='disabled'
                    onChange={(e) => {
                      settktCount((prevState) => ({
                        ...prevState,
                        bookNofChild: e.target.value,
                      }));
                      billAmountCalculation("child", e.target.value);
                    }}
                    compone
                    component={RenderField.RenderTextField}
                  />
                  {!formError.childTktStatus && billAmount.childtktPrice}
                  <div className="err-msg" style={{ fontSize: "0.8em" }}>
                    <span>{formError.childTktStatus}</span>
                  </div>
                </Col>
              </Row>

              <Field
                name="bookTravellDate"
                type="date"
                label="Travel Date"
                onChange={(e) => checkapiTimeSlot(e.target.value)}
                component={RenderField.RenderDateField}
              />
              <br />
              <div className="err-msg">
                {" "}
                <span>{formError.timeSlotapiError}</span>
              </div>

              {formAddon != "" &&
                formAddon.map((addon, index) => (
                  <div key="{index}" style={{ marginLeft: 25 }}>
                    <Label>
                      <Input
                        type="checkbox"
                        id={addon.addonFormId}
                        value={addon.addonPrice}
                        name={addon.addonName}
                        onChange={(e) => addonValues(e)}
                      />
                      {addon.addonName} &nbsp; &nbsp;
                      {"AED " + addon.addonPrice}
                    </Label>
                  </div>
                ))}
              <div className="err-msg">
                {" "}
                <span>{formError.AddonapiError}</span>
              </div>
              <div className="err-msg">
                <span>{formError.AddonAvilErr}</span>
              </div>

              <br />
              <Label>
                Total Fare &nbsp;&nbsp;&nbsp; : &nbsp;{" "}
                {userId.agencyId != 0 && userId.agencyId != 0
                  ? Number(
                      tktCount.bookNofAdult * billAmount.offerB2bAdultPrice +
                        tktCount.bookNofChild * billAmount.offerB2bChildPrice
                    )
                  : Number(
                      tktCount.bookNofAdult * billAmount.offerPrice +
                        tktCount.bookNofChild * billAmount.offerChildPrice
                    )}
                &nbsp;
              </Label>
              {userId.agencyId != 0 || userId.agencyUserId != 0 ? (
                <>
                  <div className="single-widget-search-input-title">
                    <i className=" " /> Payment Method
                  </div>
                  <Row>
                    <Col>
                      <Field
                        name="bookPaymentMode"
                        component="input"
                        type="radio"
                        checked="checked"
                        value="1"
                        onChange={(e) => setpaymentMode(e.target.value)}
                      />{" "}
                      Online
                    </Col>
                    <Col>
                      <Field
                        name="bookPaymentMode"
                        component="input"
                        type="radio"
                        value="2"
                        onChange={(e) => setpaymentMode(e.target.value)}
                      />{" "}
                      Credit
                    </Col>
                  </Row>
                </>
              ) : null}
              <br />
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  className="btn btn-yellow"
                  onClick={handleSubmit((values) =>
                    bookForAgent({
                      ...values,
                      pill: 1,
                    })
                  )}
                >
                  Book Now
                </Button>
                &nbsp;&nbsp;&nbsp;
                {/* <Button
                  color="primary"
                  className="btn btn-yellow"
                  onClick={handleSubmit((values) =>
                    bookForAgent({
                      ...values,
                      pill: 2,
                    })
                  )}
                >
                  Add To Cart
                </Button> */}
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
export default reduxForm({
  form: "AttBookingForm",
  validate,
})(AttBookingForm);
